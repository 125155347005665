import { Component, Mixins, Prop } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import ItemNameShopGet from "@/store/item-name-shop/get";
import ItemNameShopModify from "@/store/item-name-shop/modify";
import Flash from "@/store/common/flash";
import { RegisterRequest } from "@/api/item-name-shop/request";
import { GetResult } from "@/api/item-name-shop/response";
import Loading from "@/store/loading";

@Component({ components: { UIFormRow } })
export default class NewEditNames extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id!: string | null;

  @Prop({ type: Object, required: false })
  agreements: {} | undefined;

  @Prop({ type: Function, required: false })
  isNotAgreement: Function | undefined;

  inputParams: GetResult = {
    id: null,
    shopId: Number(this.id),
    cmsShopName: null,
    cmsShopNameKana: null,
    cmsState: null,
    cmsAddress1: null,
    cmsAddress2: null,
    cmsTel: null,
    cmsBusinessHours: null,
    cmsHoliday: null,
    cmsSeat: null,
    cmsParkInfo: null,
    cmsBudget: null,
    cmsReserveUrl: null,
    cmsOther: null,
    cmsInquiryMail: null,
    spShopName: null,
    spAddress: null,
    spTel: null,
    spBusinessHours: null,
    spHoliday: null,
    spSeat: null,
    spParkInfo: null,
    spBudget: null,
    spOther: null,
    spReserve: null,
    spInquiryMail: null
  };

  get loading() {
    return Loading.isLoading;
  }

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  async created() {
    await ItemNameShopGet.get({ id: Number(this.id) });
    if (!ItemNameShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ItemNameShopGet.getMessage,
        showReloadButton: true
      });
      return;
    }
    this.inputParams = ItemNameShopGet.getResult;
  }

  goBack() {
    this.$router.back();
  }

  async onSave() {
    await Flash.clear();
    await ItemNameShopModify.register(this.createRegisterRequest());
    if (ItemNameShopModify.isSuccess) {
      await this.redirectWithSuccessAlert(
        "項目名設定を保存しました。",
        "/shop-management"
      );
    } else {
      await Flash.setErrorNow({
        message: ItemNameShopModify.getMessage,
        showReloadButton: false
      });
    }
  }

  private createRegisterRequest() {
    return this.inputParams as RegisterRequest;
  }
}
