import {
    getModule,
    Module,
    MutationAction,
    VuexModule
  } from "vuex-module-decorators";
  import store from "@/store";
  import { GetResponse } from "@/api/config/response";
  import * as ConfigAPI from "@/api/config";
  import { isSuccess } from "@/api/response";
  
  const MODULE_NAME = "config/get";
  
  /**
   * 都道府県情報取得API（/help）のレスポンスを管理するストアモジュール
   */
  @Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
  class Get extends VuexModule {
    
    getResponse: GetResponse = {} as GetResponse;
    // getters
    get isSuccess() {
      return isSuccess(this.getResponse);
    }
  
    get getMessage() {
      return this.getResponse.message;
    }
  
    get getItems() {
      if (this.getResponse) {
        return this.getResponse || [];
      } else {
        return [];
      }
    }
  
    // MutationActions
    @MutationAction
    async get() {
      const getResponse = await ConfigAPI.get();
      return {
        getResponse
      };
    }
  
    @MutationAction
    async clearResponse() {
      return {
        getResponse: {} as GetResponse
      };
    }
  }
  
  export default getModule(Get);
  
