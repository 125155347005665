import service from "@/api/service";
import { GetRequest, RegisterRequest } from "@/api/link-shop/request";
import { GetResponse, RegisterResponse } from "@/api/link-shop/response";
import { CommonResponse } from "@/api/response";

/**
 * 外部リンク情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-link-shop", getRequest);
  return response.data as GetResponse;
}

/**
 * 外部リンク登録更新API
 *
 * @param registerRequest
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-link-shop", registerRequest);
  return response.data as RegisterResponse;
}
